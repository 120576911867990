import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { isAlphanumeric } from '@cargo/common/helpers';
import { TooltipContext } from "@cargo/common/tooltip";
import './badge.scss';
import { ProfileIcon } from "@cargo/common/users";
// import ProfileIcon from "../../../svg-icons/profile-icons.svg";

export class Badge extends Component {

	constructor(props) {
		super(props);
		this.badgeRef = React.createRef();
	}

	render() {

		const {
			label,
			key,
			user,
			tooltip,
			toolTipIcon,
			onPointerDown,
			onPointerEnter,
			onPointerLeave,
			...otherProps
		} = this.props;

		const name = user.full_name ? user.full_name : user.name || null;
		const badgeLetter = name ? isAlphanumeric(name) ? name.charAt(0).toLowerCase() : user.email?.charAt(0).toLowerCase()  : user.email?.charAt(0).toLowerCase();

		return !user.role ? null : (
			<TooltipContext.Consumer>
			    {(Tooltip) => 
					<button
						{...otherProps}
						className={`badge ${ this.props.className ? this.props.className : ''}`}
						ref={this.badgeRef}
						onPointerEnter={(e) => {

							if (onPointerEnter) {
								onPointerEnter(e)
							}

							if( !this.props.tooltip ){ return }


							let buttonPos = this.badgeRef.current.getBoundingClientRect();

							Tooltip.openTip?.({
							    innerText: `<div class="tooltip-email" style="padding-top: 2px; padding-bottom: 1px;">${user.full_name ? user.full_name : user.email }</div> <div style="padding-bottom: 2px; font-style: italic;">${user?.role}</div>`,
							    shortcutText: null,
							    icon: toolTipIcon,
							    buttonPos: {
							    	x: buttonPos.x,
							    	y: buttonPos.y,
							    	width: buttonPos.width,
							    	height: buttonPos.height,
							    },
							    event: e,
							})
							
						}}
						onPointerLeave={(e) => { 

							if (onPointerLeave) {
								onPointerLeave(e)
							}

							if( !this.props.tooltip ){ return } 
							Tooltip.closeTip?.(e) 


						}}
						onPointerDown={(e) => { 

							if (this.props.tooltip) {
								Tooltip.closeTip?.(e)
							}

							// run the button's original pointerDown method
							if (onPointerDown) {
								onPointerDown(e)
							}

						}}
					>
						<>
							<span 
								className={`icon${user.color ? ' filled' : ''}${onPointerDown ? ' clickable' : ''}`}
								user-role={user?.role?.toLowerCase()}
								style={{'color': user.color}}
							>
								{/* {badgeLetter} */}
								<ProfileIcon
									letter = {badgeLetter}
									color = {user.color}
								/>
							</span>
							
						</>
					</button>
				}
			</TooltipContext.Consumer>
		);

	}

};